import styles from "./TgCoin.module.scss";
import { TG_ICON } from "../../../../services/base/constants";

function TgCoin({ size }: any) {
  return (
    <div className={styles.TgCoin}>
      <div className={styles.TgCoinCount}>0.0001</div>
      <div
        className={styles.TgCoinIcon}
        dangerouslySetInnerHTML={{ __html: TG_ICON }}
      />
    </div>
  );
}

export default TgCoin;
