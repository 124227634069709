export const parseItem = (item: string) => {
  if (!item) return [];
  return item.split("|");
};

export const calcAttrFromBase = (
  baseValue: number,
  percentForLevel: number,
  increase: number | string,
) => {
  return Math.floor(baseValue * Math.pow(percentForLevel, Number(increase)));
};

export const calcAttrFromBaseWithAddition = (
  baseValue: number,
  additionPerLevel: number,
  increase: number | string,
) => {
  return Math.floor(baseValue + additionPerLevel * Number(increase));
};
