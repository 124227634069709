import { useEffect, useRef, useState } from "react";
import styles from "./Mob.module.scss";

import React from "react";

import MobHealthBar from "../base/MobHealthBar/MobHealthBar";
// import CoinDrop from '../base/CoinDrop/CoinDrop';
import AnimationAttack from "../animationAttack/AnimationAttack";
import { random } from "../../../services/base/list";
import ExpAnimate from "../expAnimate/expAnimate";
import { socketConnection } from "../../../core/api/common";
import { MOB_PREFIX } from "../../../services/base/constants";
import ItemDrop from "../base/ItemDrop/ItemDrop";

function Mob({ params }: any) {
  const [socket]: any = useState(socketConnection());
  const [stylesOfMob, changeStyles]: any = useState({});
  const [mobId, setMobId]: any = useState("");

  useEffect(() => {
    const timings = random(0.65, 1.25, false) + "s";

    setMobId(`${MOB_PREFIX}${params.posId}`);

    changeStyles({
      attack: {
        animation: `animate-attack-${params.id} ${timings} infinite`,
      },
      die: {
        animation: `animate-die-${params.id} 3.6s`,
      },
      stay: { animation: `animate-stay-${params.id} ${timings} infinite` },
    });
  }, []);

  return (
    <>
      <div
        className={`${styles.MobConainer} ${
          params.state === "die" ? styles.MobDied : ""
        }`}
        id={mobId}
        onPointerDown={$event => {
          // Вызывает панель
          if (params.state === "die") {
            // drawCoin();
          } else {
            socket.send("attack mob", params.targetId);
          }
        }}
      >
        {params.damages[params.damages.length - 1]?.isKilled ? (
          <ExpAnimate value={params.damages[params.damages.length - 1]?.exp} />
        ) : null}
        <div
          key={params.state}
          className={`${styles.Mob}`}
          style={stylesOfMob[params.state]}
        ></div>
        {params.hp !== null && (
          <div key={params.hp} className={`${styles.MobHealthBar}`}>
            <MobHealthBar
              percent={(params.hp / params.maxhp) * 100}
            ></MobHealthBar>
          </div>
        )}

        <div className={styles.Drop}>
          {params.damages[params.damages.length - 1]?.isKilled ? (
            <ItemDrop
              items={params.damages[params.damages.length - 1]?.items}
            />
          ) : null}
        </div>

        {params.damages.map((damageItem: any, idx: number) => {
          return (
            <AnimationAttack
              key={idx}
              type={damageItem.type}
              value={damageItem.value}
            />
          );
        })}
      </div>
    </>
  );
}

export default React.memo(Mob);
