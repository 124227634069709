import { useEffect, useState } from "react";
import styles from "./ItemsPager.module.scss";

import { useSelector } from "react-redux";
import store from "../../../../services/store.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCoins,
  faTrash,
  faRightLong,
  faLeftLong,
  faLock,
  faGem,
  faShirt,
  faStar,
  faShuffle,
  faHandSparkles,
  faCheck,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { socketConnection } from "../../../../core/api/common";
import { parseItem } from "../../../../services/base/item";
import { selectItem } from "../../../../services/store/items/items.store";
import { ICON_STARS } from "../../../../services/base/constants";

function LockOfCell({ elIdx, itemLength }: any) {
  return (
    <>
      {elIdx >= itemLength ? (
        <div className={styles.LockCell}>
          <FontAwesomeIcon icon={faLock} size="lg" />
        </div>
      ) : null}
    </>
  );
}

export function ItemCell({ item, withCount = true, withLevel }: any) {
  const [info, changeInfo]: any = useState(null);
  useEffect(() => {
    if (item) {
      const [itemId, level, count] = parseItem(item);
      changeInfo({ count, id: itemId, level });
    } else {
      changeInfo(null);
    }
  }, [item]);

  return (
    <>
      {info ? (
        <>
          <div className={`items items-${info.id}`}></div>
          {withCount ? <div className="ItemCount">{info.count}</div> : null}

          {withLevel && info.level > 0 ? (
            <div className={styles.ItemLevel}>
              <FontAwesomeIcon icon={faStar} />
              <span>{info.level}</span>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}

function ItemsPager({
  canClick = true,
  isSelected = false,
  items,
  maxCount,
  onClickUnlock,
  pageCount = 15,
  selectedItems,
  showLock = true,
  showPagination = true,
  type = "backpack",
}: any) {
  const [page, changePage]: any = useState(1);
  const [socket]: any = useState(socketConnection());

  return (
    <div className={styles.ItemsPager}>
      <div className="ItemsList">
        {new Array(pageCount).fill(null).map((el: any, idx: number) => {
          const cx = styles.ItemsPagerItem + " Item";
          const uniqKey = `${page}|${idx}`;
          const itemIndex = page * pageCount - pageCount + idx;

          const [itemId, increase, count] = parseItem(items[itemIndex]);

          return (
            <div
              key={uniqKey}
              className={cx}
              onClick={() => {
                if (itemId === undefined || !canClick) {
                  return;
                }
                if (isSelected) {
                  if (!itemId) return;
                  const SelItems = Object.assign({}, selectedItems[0]);
                  if (!SelItems[itemIndex]) {
                    SelItems[itemIndex] = { count: 1, isActive: false };
                  }

                  // Предметов больше 1-го (показываем модалку, чтобы положить сразу)
                  if (Number(count) > 1) {
                    store.dispatch(
                      selectItem({
                        inventoryId: itemIndex,
                        item: items[itemIndex],
                        itemId,
                        type,
                      }),
                    );
                    return;
                  }

                  SelItems[itemIndex].isActive = !SelItems[itemIndex].isActive;
                  selectedItems[1](SelItems);
                } else {
                  store.dispatch(
                    selectItem({
                      inventoryId: itemIndex,
                      item: items[itemIndex],
                      itemId,
                      type,
                    }),
                  );
                }
              }}
            >
              {itemId === undefined && showLock ? (
                <div
                  onClick={() => {
                    onClickUnlock && onClickUnlock(true);
                  }}
                >
                  <LockOfCell elIdx={itemIndex} itemLength={items.length} />
                </div>
              ) : (
                <ItemCell item={items[itemIndex]} withLevel={true} />
              )}
              {isSelected && itemId && Number(count) === 1 ? (
                <div
                  className={`${styles.ItemsPagerSelected} ${
                    selectedItems[0][itemIndex] &&
                    selectedItems[0][itemIndex].isActive
                      ? styles.Selected
                      : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      <div className={styles.ActionsBar}>
        {type === "backpack" ? (
          <div className={styles.Actions}>
            <div className={`${styles.ButtonAction} BtnWithPressed`}>
              <FontAwesomeIcon icon={faGem} size={"xl"} />
            </div>
            <div
              className={`${styles.ButtonAction} BtnWithPressed`}
              onClick={() => {
                socket.emit("shuffle items");
              }}
            >
              <FontAwesomeIcon icon={faShuffle} size={"xl"} />
            </div>
            <div
              className={`${styles.ButtonAction} BtnWithPressed`}
              onClick={() => {
                socket.emit("upgrade all items");
              }}
            >
              <FontAwesomeIcon icon={faHandSparkles} size={"xl"} />
            </div>
          </div>
        ) : null}

        {showPagination ? (
          <div className={styles.Pagination}>
            <div
              className={`${styles.PaginationArrow} ${styles.PagionationLeft} BtnWithPressed`}
              onClick={() => {
                if (page > 1) changePage(page - 1);
              }}
            >
              <FontAwesomeIcon icon={faLeftLong} size="2xl" />
            </div>
            <div className={styles.PaginationInfo}>{page}</div>
            <div
              className={`${styles.PaginationArrow} ${styles.PagionationRight} BtnWithPressed`}
              onClick={() => {
                if (maxCount > page * (pageCount + 1) || !maxCount) {
                  changePage(page + 1);
                }
              }}
            >
              <FontAwesomeIcon icon={faRightLong} size="2xl" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ItemsPager;
