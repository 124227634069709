import styles from "./DialogTrade.module.scss";
import { ICON_STARS, TG_ICON } from "../../../../../services/base/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../../../../../services/store.service";
import { setCurrentTradeStep } from "../../../../../services/store/modal/modal.store";
import { ItemCell } from "../../ItemsPager/ItemsPager";
import { GetItemName } from "../../GetProps/GetProps";
import TgCoin from "../../TgCoin/TgCoin";
import CustomRange from "../../CustomRange/CustomRange";
import { selectItem } from "../../../../../services/store/items/items.store";

function DialogLvl({ lvl }: any) {
  return (
    <div className={styles.DialogTradeCurrentLvl}>
      <div className="mr-2">Lvl</div>
      <div
        className={`${styles.DialogTradeTitleSelectLvlStar} ${styles.StarFilled}`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: ICON_STARS.fill,
          }}
        ></div>
        <div className={`${styles.DialogTradeTitleSelectLvlStarText}`}>
          {lvl}
        </div>
      </div>
    </div>
  );
}

function DialogSelectLvl({ onSelect }: any) {
  return (
    <div className={styles.DialogTradeTitleSelectLvl}>
      {new Array(100).fill(null).map((lvl, idx) => {
        const isFilled = idx % 3 === 1;
        const isDisabled = idx % 8 === 1;
        const level = idx + 1;
        return (
          <div
            key={idx}
            className={`${styles.DialogTradeTitleSelectLvlStar} ${
              isFilled ? styles.StarFilled : ""
            } ${isDisabled ? styles.StarDisabled : ""}`}
            onClick={() => {
              onSelect(level);
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: isFilled ? ICON_STARS.fill : ICON_STARS.empty,
              }}
            ></div>
            <div className={`${styles.DialogTradeTitleSelectLvlStarText}`}>
              {idx + 1}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function DialogTradeBuyCards() {
  const ITEMS = [
    "0|0|0|0",
    "1|0|0|0",
    "2|0|0|0",
    "3|0|0|0",
    "4|0|0|0",
    "5|0|0|0",
    "6|0|0|0",
    "7|0|0|0",
    "8|0|0|0",
    "9|0|0|0",
    "10|0|0|0",
    "11|0|0|0",
  ];
  return (
    <div className="ItemsList">
      {ITEMS.map((item: any, idx: number) => {
        return (
          <div
            key={idx}
            className={`${styles.DialogTradeBuyCard}`}
            onClick={() => {
              store.dispatch(setCurrentTradeStep(2));
            }}
          >
            <div
              className={`Item DisableHover ${styles.DialogTradeBuyCardCell}`}
            >
              <ItemCell item={item} withCount={false} withLevel={true} />
            </div>
            <div className="text-left">
              <GetItemName item={item} />
              <span className={styles.DialogTradeBuyCardCountExist}>33 шт</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function DialogTradeBuyCardSelectList() {
  //   const [isSelectedItem, changeSelectedItem]: any = useState(null);
  return (
    <>
      <div className={`ItemsList ${styles.DialogTradeBuyCardSelectList}`}>
        {[1, 2, 3, 4, 5].map((item: any, idx: number) => {
          return (
            <div
              key={idx}
              className={`${styles.DialogTradeBuyCardSelectItem}`}
              onClick={() => {
                // changeSelectedItem(true);
                store.dispatch(selectItem({}));
                store.dispatch(
                  selectItem({
                    item: "0|0|0|0",
                    tradeItemId: null,
                    type: "trade",
                  }),
                );
              }}
            >
              <div className={"Item DisableHover mb-4"}>
                <ItemCell item={"0|0|0|0"} withCount={false} withLevel={true} />
              </div>
              <TgCoin />
            </div>
          );
        })}
      </div>

      {/* {isSelectedItem && <DialogTradeBuyItemModal />} */}
    </>
  );
}

// function DialogTradeBuyItemModal() {
//   return (
//     <>
//       <CustomRange max={100} min={0} />
//     </>
//   );
// }

function DialogTradeBuy({ type }: any) {
  const [currentLvl, changeCurrentLvl]: any = useState(null);
  const { tradeStep } = useSelector((store: any) => store.modal);

  return (
    <>
      <div className={styles.DialogTradeTop}>
        <div className={styles.DialogTradeTitle}>{type}</div>
        {(tradeStep === 1 || tradeStep === 2) && <DialogLvl lvl={currentLvl} />}
      </div>
      {/* Выбор уровня предмета */}
      {tradeStep === 0 && (
        <DialogSelectLvl
          onSelect={(lvl: number) => {
            changeCurrentLvl(lvl);
            store.dispatch(setCurrentTradeStep(1));
          }}
        />
      )}

      {/* Выбор предмета из списка */}
      {tradeStep === 1 && <DialogTradeBuyCards />}

      {/* Выбор предмета из того что есть на аукционе */}
      {tradeStep === 2 && <DialogTradeBuyCardSelectList />}
    </>
  );
}

export default DialogTradeBuy;
