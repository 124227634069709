import {
  faAnglesDown,
  faAnglesRight,
  faClose,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./ImproveItemModal.module.scss";
import { useSelector } from "react-redux";
import { socketConnection } from "../../../../core/api/common";
import { ItemCell } from "../ItemsPager/ItemsPager";
import { parseItem } from "../../../../services/base/item";
import React from "react";
import GetProps, { GetItemName } from "../GetProps/GetProps";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";

function ImproveItemModal({ item, onClose }: any) {
  const { collections, items } = useSelector((store: any) => store.items).data;
  if (!item) return null;
  const [nextItem, changeNextItem]: any = useState(null);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    const [itemId, level, count, increase] = parseItem(item);
    const itemInfo = items[itemId];

    if (itemInfo.collection_type) {
      const collection = collections[itemInfo.collection_type];

      if (collection.items[itemId].isLast) {
        changeNextItem(
          `${collection.items[itemId].nextItem}|${Number(level) + 1}|${count}|${
            Number(increase) + 1
          }`,
        );
      } else {
        changeNextItem(
          `${collection.items[itemId].nextItem}|${level}|${count}|${
            Number(increase) + 1
          }`,
        );
      }
    }
  }, []);

  return (
    <div className={styles.ImproveContainer}>
      <ActionModalWithButtons
        buttons={[
          {
            action: () => {
              socket.send("upgrade item", item);
            },
            title: "Улучшить",
          },
        ]}
        info={
          <div className={`white-text ${styles.WarnDescription}`}>
            <div className={`${styles.WarnDescriptionIcon}`}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>
            <p className={`mt-0 mb-0 ${styles.WarnDescriptionText}`}>
              Для усиления требуется предмет такого же уровня. При неудачном
              усилении, предмет сгорает.
            </p>
          </div>
        }
        onClose={() => {
          onClose();
        }}
        withBkg={true}
      >
        <div>
          <h2 className="mt-0 white-text text-center">Улучшение предмета</h2>

          <div className={`${styles.ImproveItemItems}`}>
            <div className={`${styles.ImproveItemItem} ItemsList`}>
              {/* <div className={`text-center ${styles.ImproveItemName}`}>
                <GetItemName item={item} />
              </div> */}
              <div
                className={`${styles.ImproveItemElement} Item DisableHover mb-4`}
              >
                <ItemCell item={item} withCount={false} withLevel={true} />
              </div>
              <GetProps item={item} showName={false} />
            </div>

            <div className={`${styles.ImproveArrow}`}>
              <FontAwesomeIcon icon={faAnglesRight} />
            </div>

            <div className={`${styles.ImproveItemItem} ItemsList`}>
              {/* <div className={`text-center ${styles.ImproveItemName}`}>
                <GetItemName item={nextItem} />
              </div> */}
              <div
                className={`${styles.ImproveItemElement} Item DisableHover mb-4`}
              >
                <ItemCell item={nextItem} withCount={false} withLevel={true} />
              </div>
              <GetProps diffItem={item} item={nextItem} showName={false} />
            </div>
          </div>
        </div>
      </ActionModalWithButtons>
    </div>
  );
}

export default React.memo(ImproveItemModal);
