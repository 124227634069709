import { useRef, useState } from "react";
import styles from "./ItemInfo.module.scss";
import store from "../../../../services/store.service";
import { useSelector } from "react-redux";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import { getTranslateValue } from "../../../../services/base/translate";
import { setTempQuickItemPane } from "../../../../services/store/other/other.store";
import { selectItem } from "../../../../services/store/items/items.store";
import { ItemCell } from "../ItemsPager/ItemsPager";
import React from "react";
import GetProps from "../GetProps/GetProps";
import ImproveItemModal from "../ImproveItemModal/ImproveItemModal";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import TgCoin from "../TgCoin/TgCoin";
import CustomRange from "../CustomRange/CustomRange";
import { parseItem } from "../../../../services/base/item";
import { putOrGetFromBank } from "../../../../core/api/items/items.api";
import CreateItemModal from "../CreateItemModal/CreateItemModal";

function hasTypeAdditionalInfo(type: string) {
  const additionalInfo: any = { "bank.get": 1, "bank.put": 1, trade: 1 };
  return !!additionalInfo[type];
}

function AdditionalItemInfo({ rangeStates, selectedItem }: any) {
  switch (selectedItem.type) {
    case "trade": {
      return (
        <div className={`${styles.InfoPrice}`}>
          <div>Стоимость</div>
          <div>
            <TgCoin />
          </div>
        </div>
      );
    }
    case "bank.get":
    case "bank.put": {
      const [itemId, level, count] = parseItem(selectedItem.item);
      return (
        <div className={`${styles.InfoPrice}`}>
          <CustomRange
            max={Number(count)}
            min={1}
            onChange={(value: any) => {
              rangeStates.count[1](value);
            }}
            title="Количество"
          />
        </div>
      );
    }
  }
  return null;
}

function ItemInfo() {
  const { selectedItem } = useSelector((store: any) => store.items);

  const [socket]: any = useState(socketConnection());
  const [improveItem, changeImproveItemId]: any = useState(null);
  const statesForCustomRange = { count: useState(1) };

  const btns = [
    {
      action: () => {
        socket.send("use item", selectedItem.inventoryId);
        close();
      },
      title: "Использовать",
      type: ["backpack"],
    },
    {
      action: () => {
        socket.send("use item", selectedItem.inventoryId);
        close();
      },
      title: "Купить",
      type: ["trade"],
    },
    {
      action: () => {
        store.dispatch(setTempQuickItemPane(selectedItem.inventoryId));
        close();
      },
      title: "Закрепить",
      type: ["backpack"],
    },
    {
      action: () => {
        changeImproveItemId(selectedItem.item);
      },
      title: "Усилить",
      type: ["backpack"],
    },
    {
      action: () => {
        socket.send("delete item", selectedItem.inventoryId);
        close();
      },
      title: "Выбросить",
      type: ["backpack"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${countValue}`],
          type: "put",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Положить",
      type: ["bank.put"],
    },
    {
      action: () => {
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${9999999}`],
          type: "put",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Положить все",
      type: ["bank.put"],
    },
    {
      action: () => {
        const countValue = statesForCustomRange.count[0];
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${countValue}`],
          type: "get",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Взять",
      type: ["bank.get"],
    },
    {
      action: () => {
        putOrGetFromBank({
          cellIds: [`${selectedItem.inventoryId}:${9999999}`],
          type: "get",
        }).then((iResp: IRequestResponse) => {
          close();
        });
      },
      title: "Взять все",
      type: ["bank.get"],
    },
  ];

  function close() {
    store.dispatch(selectItem(null));
  }

  return (
    <>
      {selectedItem ? (
        <div className={styles.InfoItemContainer}>
          {selectedItem.type === "craft" && (
            <CreateItemModal
              item={selectedItem.item}
              onClose={() => {
                store.dispatch(selectItem(null));
              }}
            />
          )}

          {selectedItem.type !== "craft" && (
            <ActionModalWithButtons
              buttons={btns.filter((btn: any) => {
                if (!selectItem || !selectItem.type) return [];
                return selectedItem.type.includes(btn.type);
              })}
              info={
                hasTypeAdditionalInfo(selectedItem.type) ? (
                  <AdditionalItemInfo
                    rangeStates={statesForCustomRange}
                    selectedItem={selectedItem}
                  />
                ) : null
              }
              onClose={() => {
                close();
              }}
            >
              <div className={`${styles.InfoItem}`}>
                <div className={styles.InfoItemItem}>
                  <div className={`${styles.InfoItemItemCell} ItemsList`}>
                    <div className="Item DisableHover">
                      <ItemCell
                        item={selectedItem.item}
                        withCount={false}
                        withLevel={true}
                      />
                    </div>
                  </div>

                  <div className={styles.InfoItemItemParams}>
                    <GetProps item={selectedItem.item}></GetProps>
                  </div>
                </div>
              </div>
            </ActionModalWithButtons>
          )}

          {improveItem ? (
            <ImproveItemModal
              item={improveItem}
              onClose={() => {
                changeImproveItemId(null);
              }}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

export default React.memo(ItemInfo);
