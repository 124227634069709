import { request } from "../common";
import {
  API_ITEM_LIST,
  API_PUT_OR_GET_FROM_BANK,
  API_CRAFT_ITEM,
} from "../constants";

export const customUserApi = {
  craftItem: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_CRAFT_ITEM}`,
      withCredentials: true,
    });
  },
  getList: async () => {
    return await request({
      data: null,
      method: "GET",
      url: `${API_ITEM_LIST}`,
      withCredentials: true,
    });
  },
  putOrGetFromBank: async (data: any) => {
    return await request({
      data,
      method: "POST",
      url: `${API_PUT_OR_GET_FROM_BANK}`,
      withCredentials: true,
    });
  },
};

export const { craftItem, getList, putOrGetFromBank } = customUserApi;
