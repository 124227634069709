import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  clearDialog,
  updateDialogActionId,
} from "../../../../services/store/dialog/dialog.store";
import store from "../../../../services/store.service";

import styles from "./DialogModal.module.scss";
import DialogTradeAction from "./DialogTrade/DialogTrade";
import { getDialogState } from "../../../../core/api/dialogs/dialog.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import DialogComponent from "./DialogComponent/DialogComponent";

function DialogModal() {
  const { actionId, dialog }: any = useSelector((store: any) => store.dialog);
  const { params } = useSelector((store: any) => store.user);

  const [loadMessages, changeLoader]: any = useState(false);
  const [dialogType, changeDialogType]: any = useState(null);
  const [stateResult, changeStateResult]: any = useState(null);
  const [isLock, changeLockType]: any = useState(true);

  useEffect(() => {
    changeLockType(true);
    setTimeout(() => {
      changeLockType(false);
    }, 300);
  }, [dialog]);

  useEffect(() => {
    if (dialog && dialog.actions[actionId].loadState) {
      changeLoader(true);
      getDialogState({
        actionId,
        dialogId: dialog.id,
      }).then(resp => {
        if (resp.hasError) return;
        const { data, state } = resp.getData();
        changeStateResult(state);
        changeLoader(false);
      });
    }
  }, [actionId]);

  function closeDialog() {
    changeDialogType(null);
    changeStateResult(null);
    changeLoader(false);
    store.dispatch(clearDialog());
  }

  function parseMessage(msg: string) {
    const regex = /<([^>]+)>|\*\*([^*]+)\*\*|\*([^*]+)\*/g;

    function replaceMatch(
      match: any,
      type: string,
      boldText: string,
      italicText: string,
    ) {
      if (type) {
        switch (type) {
          case "name":
            return `<b>${params.login}</b>`;
          case "close.dialog":
            return "close.dialog";
          case "ton":
            return "TON";
          case "icon.crist":
            return "sss";
          case "component.trade.buy":
          case "component.trade.sell":
          case "component.trade.uniq":
            changeDialogType(type.split("component.")[1]);
            return "";
          case "component.bank.put":
          case "component.bank.get":
            changeDialogType(type.split("component.")[1]);
            return "";
          case "component.raks":
            changeDialogType("raks");
            return "";
          case "component.leksli":
            changeDialogType("leksli");
            return "";
          default:
            return match;
        }
      } else if (boldText) {
        return `<span class="bold">${boldText}</span>`;
      } else if (italicText) {
        return `<span class="cursive">${italicText}</span>`;
      }

      return match;
    }

    const replacedGreeting = msg.replace(regex, replaceMatch);
    return replacedGreeting;
  }

  return (
    <>
      {dialog ? (
        <div className={`${styles.DialogContainer}`}>
          <div className={styles.DialogWrapper}>
            <div
              className={`${styles.DialogContent} ${
                dialogType ? styles.DialogContentFullScreen : ""
              }`}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {dialogType ? (
                <DialogComponent
                  onClose={() => {
                    const baseActionId: any = "0";
                    store.dispatch(updateDialogActionId(baseActionId));
                    changeDialogType(null);
                  }}
                  type={dialogType}
                />
              ) : (
                <div>
                  <div className={styles.DialogWho}>{dialog.name}</div>
                  {dialog.actions[actionId].loadState ? (
                    loadMessages ||
                    !dialog.actions[actionId].availableMessages[stateResult] ? (
                      <div className={styles.DialogMessage}>Загрузка..</div>
                    ) : (
                      <div
                        className={styles.DialogMessage}
                        dangerouslySetInnerHTML={{
                          __html: parseMessage(
                            dialog.actions[actionId].availableMessages[
                              stateResult
                            ] || "",
                          ),
                        }}
                      ></div>
                    )
                  ) : (
                    <div
                      className={styles.DialogMessage}
                      dangerouslySetInnerHTML={{
                        __html: parseMessage(dialog.actions[actionId].message),
                      }}
                    ></div>
                  )}
                </div>
              )}
              <FontAwesomeIcon
                className={styles.CloseDialog}
                icon={faClose}
                onClick={closeDialog}
              />
            </div>
            <div className={styles.DialogActions}>
              {dialog.actions[actionId].actions.map(
                ({ actionId, message }: any) => {
                  return (
                    <button
                      key={actionId}
                      onClick={e => {
                        if (isLock) return;
                        e.stopPropagation();
                        if (actionId == "-1") {
                          closeDialog();
                          return;
                        }
                        store.dispatch(updateDialogActionId(actionId));
                      }}
                    >
                      {message}
                    </button>
                  );
                },
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DialogModal;
