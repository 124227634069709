import {
  faAnglesDown,
  faAnglesRight,
  faAnglesUp,
  faClose,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styles from "./CreateItemModal.module.scss";
import { useSelector } from "react-redux";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import ItemsPager, { ItemCell } from "../ItemsPager/ItemsPager";
import { parseItem } from "../../../../services/base/item";
import React from "react";
import ActionModalWithButtons from "../ActionModalWithButtons/ActionModalWithButtons";
import { craftItem } from "../../../../core/api/items/items.api";

function CreateItemModal({ item, onClose }: any) {
  const { craftedItems } = useSelector((store: any) => store.items).data;
  if (!item) return null;
  const [selectedItem, changeSelectedItem]: any = useState({});

  useEffect(() => {
    const [itemId, level, count] = parseItem(item);
    if (craftedItems[itemId] && craftedItems[itemId].items.length) {
      changeSelectedItem(craftedItems[itemId]);
    }
  }, []);

  return (
    <div className={styles.CreateContainer}>
      <ActionModalWithButtons
        buttons={[
          {
            action: () => {
              const [itemId, level, count] = parseItem(item);

              craftItem({ itemId }).then((resp: IRequestResponse) => {
                if (resp.hasError) return;
              });
            },
            title: "Создать",
          },
        ]}
        info={
          <div className={`white-text ${styles.WarnDescription}`}>
            <div className={`${styles.WarnDescriptionIcon}`}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>
            <p className={`mt-0 mb-0 ${styles.WarnDescriptionText}`}>
              Для создания предмета требуются ресурсы. При неудаче ресурсы не
              теряются.
            </p>
          </div>
        }
        onClose={() => {
          onClose();
        }}
        withBkg={true}
      >
        <div className={`ItemsList ${styles.CreateItemChild}`}>
          <h2 className="mt-0 white-text text-center w-100">
            Создание предмета
          </h2>

          <div className={`${styles.CreateItemItems}`}>
            <div className={`${styles.CreateItemItem}`}>
              <div
                className={`${styles.CreateItemElement} Item DisableHover mb-4`}
              >
                <ItemCell item={selectedItem.result} withLevel={true} />
              </div>
            </div>
          </div>
          <div className={`${styles.CreateArrow}`}>
            <FontAwesomeIcon icon={faAnglesUp} />
          </div>

          <div className={styles.CreateModItems}>
            {(selectedItem.items || []).map((it: any, idx: number) => {
              return (
                <div key={idx} className="Item">
                  <ItemCell item={it} withCount={true} withLevel={true} />
                </div>
              );
            })}
          </div>
        </div>
      </ActionModalWithButtons>
    </div>
  );
}

export default React.memo(CreateItemModal);
