import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./LocationIcons.module.scss";
import Map from "../Map/Map";
import { isVillage } from "../../../../services/store/location/location.store";
import store from "../../../../services/store.service";
import {
  IRequestResponse,
  socketConnection,
} from "../../../../core/api/common";
import ChestLocation from "../ChestLocation/ChestLocation";
import { getGroupInfo } from "../../../../core/api/group/group.api";
import SelectDungeonLevel from "../SelectDungeonLevel/SelectDungeonLevel";

function LocationIcons() {
  const [isMapActive, setIsMapActive]: any = useState(false);
  const [isChestActive, changeIsChestActive]: any = useState(false);
  const [dungeonLevel, setDungeonLevel]: any = useState(null);
  const [groupData, changeGroupData]: any = useState(null);
  const [socket]: any = useState(socketConnection());

  useEffect(() => {
    getGroupInfo().then((resp: IRequestResponse) => {
      if (resp.hasError) return;
      const groupData = resp.getData();
      if (groupData && groupData.state === "start") {
        setDungeonLevel(groupData.dungeonLevel);
        changeGroupData(groupData);
      }
    });
  }, []);

  return (
    <div className={styles.LocationIcons}>
      <div
        className={`${styles.LocationIconsMain} ${
          isVillage(store.getState()) ? styles.MainAvailable : null
        }`}
      >
        <div
          className={`${styles.MapIcon} BtnWithPressed`}
          onClick={() => {
            setIsMapActive(true);
          }}
        ></div>
        <div
          className={`${styles.ArenaIcon} BtnWithPressed`}
          onClick={() => {
            setIsMapActive(true);
          }}
        ></div>
        <div
          className={`${styles.RatingIcon} BtnWithPressed`}
          onClick={() => {
            setIsMapActive(true);
          }}
        ></div>
        <div
          className={`${styles.SettingIcon} BtnWithPressed`}
          onClick={() => {
            setIsMapActive(true);
          }}
        ></div>
      </div>

      <div className={styles.LocationGroupIcons}>
        {isVillage(store.getState()) ? null : (
          <>
            <div
              className={`${styles.ChestIcon} BtnWithPressed`}
              id={"icon-chest"}
              onClick={() => {
                changeIsChestActive(true);
              }}
            ></div>
            <div
              className={`${styles.ExitIcon} BtnWithPressed`}
              onClick={() => {
                socket.send("exit group");
              }}
            ></div>
          </>
        )}
      </div>

      {isMapActive
        ? (() => {
            const portalRoot = document.getElementById("portal-root");
            return portalRoot
              ? ReactDOM.createPortal(
                  <SelectDungeonLevel
                    onClose={() => setIsMapActive(false)}
                    onSelectedMap={(lvl: number) => {
                      setDungeonLevel(lvl);
                      setIsMapActive(false);
                    }}
                  />,
                  portalRoot,
                )
              : null;
          })()
        : null}

      {dungeonLevel ? (
        <Map
          dungeonLevel={dungeonLevel}
          groupData={groupData}
          onClose={() => {
            setDungeonLevel(null);
            changeGroupData(null);
          }}
        />
      ) : null}

      {isChestActive ? (
        <ChestLocation
          onClose={() => {
            changeIsChestActive(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default LocationIcons;
